jQuery(document).ready(function( $ ) {

  const donateForm = document.querySelector('.donate-form');

  if(donateForm) {
    const donateInput = donateForm.querySelector('.donate-form__input');
    const giveOnce = donateForm.querySelector('[data-amount]');
    const giveMonthly = donateForm.querySelector('[data-amount="monthly"]');

    // Placeholder, change if needed
    const minDonation = 5;
    const donateURL = "https://maineimmigrantrights.giv.sh/0514";
    let donationAmount = 0;
    let donationFrequency = "";

    if(donateInput && giveOnce && giveMonthly) {

      donateInput.addEventListener("input", function(e) {
        // Update the donation amount
        donationAmount = donateInput.value.trim();
      });

      giveOnce.addEventListener("click", function(e) {
        donationFrequency = "";
        giveMonthly.classList.remove('donate-form__button--active');
        giveOnce.classList.add('donate-form__button--active');
      });

      giveMonthly.addEventListener("click", function(e) {
        donationFrequency = "&interval=monthly";
        giveMonthly.classList.add('donate-form__button--active');
        giveOnce.classList.remove('donate-form__button--active');
      });
    }


    function checkDonateForm() {
      // If inputs are valid, and the selected/entered amount is greater than minimum
      if (donateForm.checkValidity() && donationAmount >= minDonation) {

        // Build link
        let link = `${donateURL}?amount=${donationAmount}${donationFrequency}`;

        // Navigate to link
        window.location.assign(link);
      }
    }

      donateForm.addEventListener("submit", function(event) {
        event.preventDefault();
        checkDonateForm();
      });



  }


    // if( $( '.donate-form' ).length ) {

    //   let donateForm = $( '.donate-form' );

    //   $( '.donate-form .donate-form__button' ).on( 'click', function( e ) {
    //     e.preventDefault();
    //     if( $( this ).siblings().hasClass( 'donate-form__button--active' ) ) {
    //       $( this ).siblings().removeClass( 'donate-form__button--active' )
    //     }
    //     $( this ).addClass( 'donate-form__button--active' );

    //   } )
    //   $( '.donate-form .donate-form__donate' ).on( 'click', function( e ) {
    //     e.preventDefault();



    //     let url = 'https://maineimmigrantrights.giv.sh/0514';
    //     let interval = '&interval=' . $( '.donate-form .donate-form__button--active' ).data( 'amount' ) ? $( '.donate-form .donate-form__button--active' ).data( 'amount' ) : '',
    //     amount = $( '.donate-form .donate-form__input' ).value ? $( '.donate-form .donate-form__input' ).value : '100';


    //     window.location.href = `${url}?amount=${amount}${interval}`;

    //   } )
    // }
  }(jQuery)

 )